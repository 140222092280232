import React, { useState } from "react";
import {
    Box,
    Grid,
    makeStyles, TextField, InputAdornment
} from "@material-ui/core";
import { Link } from "react-scroll";
import ApiConfig from "src/config/APIConfig";
import axios from "axios";
import toast from 'react-hot-toast';
import ButtonCircularProgress from "src/Components/ButtonCircularProgress";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 60,
        background: "#071329",
        padding: "60px 31px 5px",
        [theme.breakpoints.only('xs')]: {
            padding: "10px",
        },
        [theme.breakpoints.only('sm')]: {
            padding: "30px 31px 5px",
        },
        [theme.breakpoints.only('md')]: {
            padding: "40px 31px 5px",
        },
        [theme.breakpoints.only('lg')]: {
            padding: "60px 31px 5px",
        },
    },
    logoSection: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        [theme.breakpoints.only('xs')]: {
            gap: "10px",
            alignItems: "center"
        },

    },
    logo: {
        width: "100%",
        maxWidth: "251px",
        [theme.breakpoints.only('xs')]: {

        },
    },
    logoText: {
        color: "#FFF",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
        textAlign: "justify",
        textAlign: "left",
        [theme.breakpoints.only('xs')]: {
            textAlign: "center",
        },
    },
    footerHeading: {
        color: "#FFF",
        fontSize: "25px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "normal",
        marginBottom: "30px",
        [theme.breakpoints.only('xs')]: {
            marginBottom: "15px",
        },
    },
    list: {
        listStyleType: "none",
        paddingInlineStart: 0,
        [theme.breakpoints.only('xs')]: {

        },
    },
    listTitle: {
        color: "#FFF",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "normal",
        marginBottom: "12px",
        [theme.breakpoints.only('xs')]: {
            marginBottom: 10
        },
    },
    joinSection: {
        display: "flex",
        flexDirection: "column",
        padding: "0px 20px 20px",
        [theme.breakpoints.only('xs')]: {
            padding: 10
        },
    },
    navigateSection: {
        padding: "0px 20px 20px",
        [theme.breakpoints.only('xs')]: {
            padding: 10
        },
    },
    textField: {
        marginTop: "18px",
        borderRadius: "6px",
        border: "1px solid var(--axa, #FDC300)",
        background: "rgba(23, 0, 56, 0.25)",
        [theme.breakpoints.only('xs')]: {

        },
        "& .MuiOutlinedInput-input": {
            color: "white"
        },
        "& .MuiOutlinedInput-adornedEnd": {
            paddingRight: "6px"
        }
    },
    joinButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "transparent",
        border: "none",
        cursor: "pointer"
    }
}));
const useFulLinks = [{ name: "About Us", id: "about" }, { name: "Features", id: "features" }, { name: "Faq", id: "faq" }, { name: "Roadmap", id: "roadmap" },];
const help = ["Contact Us", "Terms & Conditions", "Privacy Policy"];

function Footer(props) {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const openPdfInNewTab = () => {
        const pdfUrl = "/Doras-WP 4.0.docx.pdf"; // Replace with your PDF URL
        window.open(pdfUrl, "_blank");
    };
    const handleJoin = async () => {
        setLoading(true);
        try {
            const res = await axios.post(
                "https://doras-node.bitviraj.com/v0/users/join-us",
                {
                    email: email,
                }
            );
            if (res.status === 200) {
                if (res.data.message == "E11000 duplicate key error collection: uat-Bass-Ico.user_join_schemas index: email_1 dup key: { email: \"sachin@gmail.com\" }") {
                    toast.success("Already exist.");
                    setLoading(false);
                }
                else {
                    toast.success(res.data.message);
                    setEmail("");
                    setLoading(false);
                }
            }
        } catch (err) {
            setLoading(false);
        }
    };
    return (
        <>
            <Box className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Box className={classes.logoSection}>
                            <img className={classes.logo} src="/images/logo/logo.svg" alt="logo" />
                            <p className={classes.logoText}>The industry-winning BaaS digital transformation platform Doras Blockchain Business Cloud offers full enterprise blockchain solutions for today's new digital assets and contemporary business networks.</p>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box className={classes.navigateSection}>
                            <p className={classes.footerHeading}>Useful Links</p>
                            <ul className={classes.list}>
                                {useFulLinks.map((data) => {
                                    return (

                                        <li className={classes.listTitle} style={{ cursor: "pointer" }}>
                                            <Link to={data.id} smooth={true} duration={500}>
                                                {data.name}
                                            </Link>
                                        </li>
                                    )
                                })}
                                <li className={classes.listTitle} style={{ cursor: "pointer" }} onClick={openPdfInNewTab}>
                                    Whitepaper
                                </li>
                            </ul>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box className={classes.navigateSection}>
                            <p className={classes.footerHeading}>Help</p>
                            <ul className={classes.list}>
                                {help.map((data) => {
                                    return (
                                        <li className={classes.listTitle}>{data}</li>
                                    )
                                })}
                            </ul>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box className={classes.joinSection}>
                            <p className={classes.footerHeading}>Join our Community</p>
                            <p className={classes.logoText}>Be part of something bigger—join our community today and connect with like-minded individuals who share your interests and passions.</p>
                            <TextField
                                className={classes.textField}
                                onChange={(e) => setEmail(e.target.value)}
                                variant="outlined"
                                placeholder="johndoe@email.com"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <button onClick={handleJoin} className={classes.joinButton}
                                                disabled={!email || loading}> {loading ? <ButtonCircularProgress /> : <img src="/images/subscribeIcon.svg
                                        " alt="submit" />}</button>

                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>


            </Box>
        </>
    );
}

export default Footer;
