// SideDrawer.js
import React from "react";
import "./sideBar.css"; // Import your CSS for styling
import CloseIcon from "@material-ui/icons/Close";
// import Tooltip from '@material-ui/core/Tooltip';
import { Link } from "react-scroll";
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from "react-router-dom";
const data = [
  { name: "HOME", id: "home" },
  { name: "ABOUT US", id: "About-Us" },
  { name: "FEATURES", id: "features" },
  { name: "FAQ's", id: "faq" },
  { name: "ROADMAP", id: "roadmap" },
  // { name: "WHITEPAPER", id: "whitepaper" },
];
function SideDrawer(props) {
  const history = useHistory();
  // Use props to determine whether the side drawer is open or closed
  const { isOpen, onClose } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <div className={`side-drawer ${isOpen ? "open" : ""}`}>
      <nav>
        <div className="close-div" onClick={onClose}>
          <CloseIcon />
        </div>
        <ul>
          {data.map((data) => {
            return (
              <li>
                <Link to={data.id} smooth={true} duration={500}>
                  {data.name}
                </Link>
              </li>
            );
          })}
            <li style={{ cursor: "pointer" }} onClick={handleClick}>
            Documents
          
          </li>
          <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={()=>{handleClose();history.push("/whitepaper")}}>Whitepaper</MenuItem>
              <Tooltip title="Coming soon">
              <MenuItem onClick={handleClose}>Gitpaper</MenuItem>
              </Tooltip>
              {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
            </Menu>
          {/* <li style={{ cursor: "default" }}><Tooltip title="Coming soon">NFT</Tooltip></li> */}
        </ul>
      </nav>
    </div>
  );
}

export default SideDrawer;
