import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "./Layouts/HomeLayout"
import LoginLayout from "./Layouts/LoginLayout"
import DashboardLayout from "./Layouts/DashboardLayout";
import StaticLayout from "./Layouts/staticLayout"
export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("./View/Pages/Home")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("./View/Auth/Login")),
  },
  {
    exact: true,
    path: "/register",
    layout: LoginLayout,
    component: lazy(() => import("./View/Auth/Register")),
  },
  {
    exact: true,
    path: "/forget-password",
    layout: LoginLayout,
    component: lazy(() => import("./View/Auth/ForgotPassword")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("./View/Auth/ResetPassword")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("./View/Auth/verify-OTP")),
  },
  {
    exact: true,
    path: "/dashboard",
    guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("./View/Pages/dashboard")),
  },
  {
    exact: true,
    path: "/transaction-history",
    layout: DashboardLayout,
    guard:true,
    component: lazy(() => import("./View/Pages/transactionHistory")),
  },
  {
    exact: true,
    path: "/buy-token",
    guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("./View/Pages/token")),
  },
  {
    exact: true,
    path: "/kyc",
    layout: DashboardLayout,
    guard:true,
    component: lazy(() => import("./View/Pages/KYC")),
  },
  // {
  //   exact: true,
  //   path: "/whitepaper",
  //   layout: DashboardLayout,
  //   guard:true,
  //   component: lazy(() => import("./View/Pages/WhitePaper"))
  // },
  {
    exact: true,
    path: "/referral",
    layout: DashboardLayout,
    guard:true,
    component: lazy(() => import("./View/Pages/Refferal")),
  },
  {
    exact: true,
    path: "/referral-points",
    layout: DashboardLayout,
    guard:true,
    component: lazy(() => import("./View/Pages/ReferralPoints")),
  },
  {
    exact: true,
    path: "/login-history",
    layout: DashboardLayout,
    guard:true,
    component: lazy(() => import("./View/Pages/LoginHistory")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    guard:true,
    component: lazy(() => import("./View/Pages/ChangePassword")),
  },
  {
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("./View/Pages/editProfile")),
  },
  {
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("./View/Pages/Static/privacyPolicy")),
  },
  {
    path: "/terms-condition",
    layout: HomeLayout,
    component: lazy(() => import("./View/Pages/Static/termsCondition")),
  },
  {
    path: "/whitepaper",
    layout: HomeLayout,
    component: lazy(() => import("./View/Pages/Static/gitPaper")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("./View/Error/notFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];


