import React, { useEffect, Suspense, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"; // Use BrowserRouter as Router for the default browser router
import PageLoading from "./Components/pageLoading";
import "./style.css";
import { routes } from "./routes";
import MomentUtils from "@date-io/moment";
import { createBrowserHistory } from "history";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Toaster } from 'react-hot-toast';
import AuthContext from "src/context/Auth";
import AuthGuard from "src/Components/AuthGuard";
const history = createBrowserHistory();
export default function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <AuthContext>
      <Router history={history}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Toaster />
            <RenderRoutes data={routes} />
          </MuiPickersUtilsProvider>
        </Router>
      </AuthContext>
    </div>
  );
}

function RenderRoutes(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {props.data.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard ? AuthGuard : Fragment;
          const Layout = route.layout || Fragment;
          return (
            <Route
              exact={route.exact}
              key={i}
              path={route.path}
              render={(routeProps) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes data={route.routes} />
                    ) : (
                      <Component {...routeProps} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}
