import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  IconButton,
  Dialog,
  Tooltip,
} from "@material-ui/core";
import SwipeableTemporaryDrawer from "../RightDrawer";
import Logo from "src/Components/Logo";
import DialogContent from "@material-ui/core/DialogContent";
import { useLocation, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    height: 70,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  desktopDrawer: {
    position: "absolute",
    right: 80,
    top: 30,
    width: "100%",
    borderRadius: "25px",
    // background:
    //   "linear-gradient(144deg, #00ACEB 0%, #00B0ED -0.82%, #1069C2 70.35%, #1069C2 100%)",
    height: 266,
    background: "#004AAD",
    [theme.breakpoints.down("sm")]: {
      width: 600,
      right: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      right: 0,
    },
  },
  iconbutton: {
    color: theme.palette.secondary.main,
  },
  search: {
    height: "50px",
    width: "100%",
    maxWidth: "376px",
    color: "#ABABAB",
    borderRadius: "100px",
    display: "flex",
    backgroundColor: "#302F35",
    alignItems: "center",
    justifyContent: "left",
    margin: "0px 5px",
    marginTop: "5px",
    marginLeft: "8px",
    "& input": {
      color: "#fff",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  searchInput: {
    color: "#ABABAB",
    cursor: "text",
    display: "inline-flex",
    position: "relative",
    fontSize: "14px",
    boxSizing: "border-box",
    alignItems: "center",
    fontWeight: 400,
    lineHeight: "1.1976em",
    width: "71%",
  },
  searchIcon: {
    fontSize: "16px",
    paddingLeft: "10px",
    color: "#fff",
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  NotificationListStyle: {
    background: theme.palette.background.Notification,
    color: theme.palette.text.NotificationColor,
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => { },
};

export default TopBar;

export function TopBarData() {
  const history = useHistory
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = useState(false);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  return (
    <>
      <img src="images/logo/logo.svg" alt="logo"
        style={{
          width: "100%",
    maxWidth: "100px",cursor:"pointer",
        }}
        onClick={()=>history.push("/")}
      />
      <Box flexGrow={1} />
      <SwipeableTemporaryDrawer />
    </>
  );
}
