import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import {useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#000",
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
    minHeight: "100vh",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    maxWidth: "100%",
    minHeight: "100vh",

  },
  content: {
    paddingTop: "106px",
    flex: "1 1 auto",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    background:"#FFFFFF",
    WebkitOverflowScrolling: "touch",
    padding: "60px 30px 10px ",


    [theme.breakpoints.only('xs')]: {
      padding: "110px 10px 10px ",
  
  },
  [theme.breakpoints.only('sm')]: {
    padding: "110px 10px 10px ",
  },
  
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
 
  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content} id="main-scroll">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
