import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import ApiConfig from "src/config/APIConfig";
import { calculateTimeLeft } from "src/View/Auth/ForgotPassword/timer";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  console.log(accessToken,"access")
  if (accessToken) {
    localStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("token");
  return accessToken ? true : false;
}


export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    checkLogin();
  }, [window.localStorage.getItem("token")]);
  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  useEffect(() => {
    const token = window.localStorage.getItem("token");
    if (token) {
      getProfileHandler(token);
    }
  }, []);

  const getProfileHandler = async (token) => {
    try {
      const response = await axios({
        method: "GET",
        url: ApiConfig.getUserProfile,
        headers: {
          token: token
      }})
      if (response.data.responseCode === 200) {
        setUserData(response?.data.responseData);
      }
    } catch (error) {
      console.log("ERROR-----", error);
    }
  };
 
  let data = {
    userLoggedIn: isLogin,
    userData,
    setEndtime,
    setTimeLeft,
    timeLeft,
    endTime,
    getProfileHandler: () => getProfileHandler(),
    userLogin: (data, type) => {
      setSession(data);
      setIsLogin(type);
    }
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
