// Header.js
import React, { useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import "./header.css";
import SideDrawer from "./sidebar";
import { useHistory } from "react-router-dom";
import { Link } from "react-scroll";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
const data = [
  { name: "HOME", id: "home" },
  { name: "ABOUT US", id: "about" },
  { name: "FEATURES", id: "features" },
  { name: "FAQ's", id: "faq" },
  { name: "ROADMAP", id: "roadmap" },
];
function Header() {
  const history = useHistory();
  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleSideDrawer = () => {
    setIsSideDrawerOpen(!isSideDrawerOpen);
  };

  const openPdfInNewTab = () => {
    const pdfUrl = "/Doras-WP 4.0.docx.pdf"; // Replace with your PDF URL
    window.open(pdfUrl, "_blank");
  };
  const token = window.localStorage.getItem("token");
  return (
    <header className="header">
      <div className="logo">
        <img src="/images/logo/logo.svg" alt="Logo" />
      </div>
      <nav className="nav">
        <ul>
          {data.map((data) => {
            return (
              <>
                <li style={{ cursor: "pointer" }}>
                  <Link to={data.id} smooth={true} duration={500}>
                    {data.name}
                  </Link>
                </li>
              </>
            );
          })}
          <li style={{ cursor: "pointer" }} onClick={handleClick}>
            Documents
          </li>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={openPdfInNewTab}>Whitepaper</MenuItem>
            <Tooltip title="Coming soon">
              <MenuItem onClick={handleClose}>Gitpaper</MenuItem>
            </Tooltip>
            {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
          </Menu>
        </ul>
      </nav>
      <div className="buy-button">
        {token ? <button className="buy-now" onClick={() => history.push("/dashboard")}>
          Dashboard
        </button> : <>        <button className="buy-now" onClick={() => history.push("/login")}>
          Login
        </button>
          &nbsp;
          &nbsp;
          <button className="buy-now signup-btn" onClick={() => history.push("/register")}>
            Sign Up
          </button> </>}

        <div className="hamburger-css">
          <MenuIcon onClick={toggleSideDrawer} />
        </div>
      </div>
      <SideDrawer isOpen={isSideDrawerOpen} onClose={toggleSideDrawer} />
    </header>
  );
}

export default Header;
