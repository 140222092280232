import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import AOS from "aos";
import { useLocation } from "react-router-dom";
import "aos/dist/aos.css";

AOS.init({ once: true });

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: "linear-gradient(180deg, #091223 1.99%, #162F3E 50.01%, #10202E 100%)",
  },



}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div className={classes.root}>
      <div className="logo" style={{display:"flex"}}>
        <img src="/images/logo/logo.svg" alt="Logo" />
      </div>
      <div className={classes.content}>{children}</div>

    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
