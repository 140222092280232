import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import AOS from "aos";
import { useLocation } from "react-router-dom";
import "aos/dist/aos.css";

AOS.init({ once: true });

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "linear-gradient(180deg, #091223 1.99%, #162F3E 50.01%, #10202E 100%)",
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    overflow:"auto"
  },


  content: {
    height:"fit-content",
    width: "100%",
    maxWidth: "420px",
    padding: "100px",
    borderRadius: "33px",
    margin: "35px",
    background: "#0A182E",
    [theme.breakpoints.only("xs")]: {
      padding: "20px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: " 20px 30px",
    },
    [theme.breakpoints.only("md")]: {
      padding: "20px 40px",
    },
    [theme.breakpoints.only("lg")]: {
      padding: "20px 80px",
    },
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div className={classes.root}>

      <div className={classes.content}>{children}</div>

    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
